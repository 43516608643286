import React, { useEffect, useState } from 'react';
import { usePopup } from './PopupContext';
import styled from 'styled-components';

const PopupWindow = styled.div`
  position: absolute;
  border: 1px solid black;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  z-index: ${({ zIndex }) => zIndex};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const PopupHeader = styled.div`
  background: #f1f1f1;
  padding: 10px;
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupContent = styled.div`
  padding: 10px;
  flex-grow: 1;
`;

const ResizableHandle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  cursor: nwse-resize;
`;

const Popup = ({ id, content }) => {
  const { removePopup, bringToFront, popups } = usePopup();
  const popup = popups.find(p => p.id === id);
  const zIndex = popup ? popup.zIndex : 0;
  
  const [position, setPosition] = useState(popup.position);
  const [size, setSize] = useState({ width: 300, height: 200 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
  const [minimized, setMinimized] = useState(false);

  const MIN_WIDTH = 200;
  const MIN_HEIGHT = 100;

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        setPosition({
          x: position.x + (e.clientX - mouseStart.x),
          y: position.y + (e.clientY - mouseStart.y),
        });
        setMouseStart({ x: e.clientX, y: e.clientY });
      }
      if (isResizing) {
        const newWidth = size.width + (e.clientX - mouseStart.x);
        const newHeight = size.height + (e.clientY - mouseStart.y);

        setSize({
          width: newWidth > MIN_WIDTH ? newWidth : MIN_WIDTH,
          height: newHeight > MIN_HEIGHT ? newHeight : MIN_HEIGHT,
        });

        setMouseStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, mouseStart, position, size]);

  const handleMouseDown = (e, type) => {
    setMouseStart({ x: e.clientX, y: e.clientY });
    if (type === 'drag') setIsDragging(true);
    if (type === 'resize') setIsResizing(true);
    bringToFront(id); // bring to front on mouse down
  };

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  return (
    <PopupWindow
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: minimized ? `${MIN_WIDTH}px` : `${size.width}px`,
        height: minimized ? `${MIN_HEIGHT - 57.5}px` : `${size.height}px`,
        zIndex: zIndex,
      }}
      onMouseDown={() => bringToFront(id)} // bring to front on click
    >
      <PopupHeader
        onMouseDown={(e) => handleMouseDown(e, 'drag')}
      >
        <span>Popup</span>
        <div>
          <button onClick={toggleMinimize}>
            {minimized ? 'Expand' : '_'}
          </button>
          <button onClick={() => removePopup(id)}>X</button>
        </div>
      </PopupHeader>
      {!minimized && (
        <>
          <PopupContent>{content}</PopupContent>
          <ResizableHandle
            onMouseDown={(e) => handleMouseDown(e, 'resize')}
          />
        </>
      )}
    </PopupWindow>
  );
};

export default Popup;
