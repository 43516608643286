import React, { createContext, useContext, useState } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popups, setPopups] = useState([]);
  const [lastPosition, setLastPosition] = useState({ x: 50, y: 50 });

  const addPopup = (content) => {
    const maxZIndex = Math.max(...popups.map(p => p.zIndex), 0);
    const newPopup = {
      id: Date.now(),
      content,
      zIndex: maxZIndex + 1,
      position: { x: lastPosition.x + 10, y: lastPosition.y + 10 }
    };
    setPopups([...popups, newPopup]);
    setLastPosition(newPopup.position);
  };

  const removePopup = (id) => {
    setPopups(popups.filter(popup => popup.id !== id));
  };

  const bringToFront = (id) => {
    const maxZIndex = Math.max(...popups.map(p => p.zIndex), 0);
    setPopups(popups.map(popup =>
      popup.id === id ? { ...popup, zIndex: maxZIndex + 1 } : popup
    ));
  };

  return (
    <PopupContext.Provider value={{ popups, addPopup, removePopup, bringToFront }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  return useContext(PopupContext);
};
