import React from 'react';
import { PopupProvider, usePopup } from './PopupContext';
import PopupManager from './PopupManager';

const MainComponent = () => {
  const { addPopup } = usePopup();

  return (
    <div>
      <button onClick={() => addPopup('Hello, this is a popup!')}>
        Open Popup
      </button>
    </div>
  );
};

const App = () => {
  return (
    <PopupProvider>
      <MainComponent />
      <PopupManager />
    </PopupProvider>
  );
};

export default App;
