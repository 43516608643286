import React from 'react';
import { usePopup } from './PopupContext';
import Popup from './Popup';
import styled from 'styled-components';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const PopupManager = () => {
  const { popups } = usePopup();

  return (
    <PopupContainer>
      {popups.map(popup => (
        <Popup key={popup.id} id={popup.id} content={popup.content} />
      ))}
    </PopupContainer>
  );
};

export default PopupManager;
